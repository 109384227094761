.body {
    background-color: black;
    background-image: radial-gradient(
            /*rgba(14, 96, 14, 0.75), black 120%*/
            rgba(0, 150, 0, 0.75), black 120%
    );
    height: 100vh;
    margin: 0;
    overflow: auto;

    /*color: #63cd18;*/
    color: white;
    font: 1.3rem condensedRegular3270, monospace;
    text-shadow: 0 0 5px #C8C8C8;
}

.body::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;

    /* allows selecting the text */
    pointer-events: none;

    background: repeating-linear-gradient(
            0deg,
            rgba(0,0,0, 0.15),
            rgba(0,0,0, 0.15) 1px,
                /*transparent 1px,*/
                /*transparent 2px*/
            transparent 3px,
            transparent 5px
    );
}

 /* selected text is blue */
::selection {
    background: #0080FF;
    text-shadow: none;
}

.scanline {
    width: 100%;
    height: 100px;
    z-index: 8;
    background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 0) 0%,
            rgba(255, 255, 255, 0.2) 10%,
            rgba(0, 0, 0, 0.1) 100%
    );
    opacity: 0.1;
    position: absolute;
    bottom: 100%;
    animation: scanline 10s linear infinite;
}

@keyframes scanline {
    0% {
        bottom: 100%;
    }
    80% {
        bottom: 100%;
    }
    100% {
        bottom: 0%;
    }
}