.vStack {
    padding: 12px;
    margin: 120px 12px 12px;
    align-items: flex-start !important;
    flex-direction: column;
}

.text {
    text-transform: uppercase;
}


.input {
    /*position: relative;*/
    caret-color: transparent !important;
}

[contenteditable] {
    caret-color: transparent; /** This hides the default browser caret */
    outline: none;
}

/* Puts a blinking square after the content as replacement for caret */
.input:after,
.typer.active:after {
    content: "■";
    animation: cursor 1s infinite;
    animation-timing-function: step-end !important;
    /*margin-left: 1px !important;*/
    /*line-height: 1rem !important;*/
}

.typer {
    word-break: break-all;
}

/* Inserts the > before terminal input */
.input:before,
.typer:before {
    content: ">" !important;
    /*position: absolute;*/
    /*padding-left: 1.5rem;*/
    /*left: 0;*/
}

@keyframes cursor {
    0% {
        opacity: 1;
    }
    25% {
        opacity: 0;
    }
    75% {
        opacity: 1;
    }
    100% {
        opacity: 1;
    }
}
