.card {
    margin: 0;
    padding: 0;
}

.cardTop, .cardBottom {
    width: 402px;
    height: 6px;
    background-image: url("../../images/cardMargin.svg") !important;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
}

.cardBottom {
    transform: rotate(180deg);
}
