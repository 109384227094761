.frame {
    width: 1102px;
    height: 702px;
    /* TODO - change with position*/
    margin: 200px 0 30px 60px;
    background-image: url("../../images/frame.svg");
    background-position: center;
    background-repeat: no-repeat;
}

.frameLine {

    width: 1133px;
    height: 15px;
    margin-left: 31px !important;
    margin-bottom: 30px !important;
    background-image: url("../../images/frameLine.svg");
    background-position: center;
    background-repeat: no-repeat;
}

.network {
    margin-left: 40px !important;
    margin-right: 15px !important;
    padding-bottom: 4px;
    font: 0.9rem condensedRegular3270, monospace;
    text-shadow: none;
}
