@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300&display=swap');

.robotoFont {
  font-family: 'Roboto', 'sans-serif';
}

@font-face {
  font-family: 'Regular3270';
  src: local('Regular3270'), url(./fonts/Regular3270.woff) format('woff');
}

@font-face {
  font-family: 'CondensedRegular3270';
  src: local('CondensedRegular3270'), url(./fonts/CondensedRegular3270.woff) format('woff');
}

.regular3270 {
  font-family: "Regular3270", 'sans-serif'
}

.condensedRegular3270 {
  font-family: "CondensedRegular3270", 'sans-serif'
}
