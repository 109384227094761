.image {
    width: 200px;
}

.name {
    /*border: 1px solid red;*/
    width: 100px !important;
    margin: 0 !important;
    padding: 0 !important;
}

.details {
    /*border: 1px solid red;*/
}

.link {
    color: white !important;
    /*font: 1.3rem condensedRegular3270, monospace;*/
    /*text-shadow: 0 0 5px #C8C8C8;*/
}

.link:hover {
    color: #63cd18 !important;
    text-decoration: none !important;
}

.link:focus {
    text-decoration: none !important;
    border: none !important;
    box-shadow: none !important;
}

.link:active {
    text-decoration: none !important;
}

