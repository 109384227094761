@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300&display=swap);
.robotoFont {
  font-family: 'Roboto', 'sans-serif';
}

@font-face {
  font-family: 'Regular3270';
  src: local('Regular3270'), url(/static/media/Regular3270.677df91a.woff) format('woff');
}

@font-face {
  font-family: 'CondensedRegular3270';
  src: local('CondensedRegular3270'), url(/static/media/CondensedRegular3270.991b9b7d.woff) format('woff');
}

.regular3270 {
  font-family: "Regular3270", 'sans-serif'
}

.condensedRegular3270 {
  font-family: "CondensedRegular3270", 'sans-serif'
}

.Card_card__3_jzl {
    margin: 0;
    padding: 0;
}

.Card_cardTop__3DB4s, .Card_cardBottom__3_LM0 {
    width: 402px;
    height: 6px;
    background-image: url(/static/media/cardMargin.ac032b04.svg) !important;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
}

.Card_cardBottom__3_LM0 {
    transform: rotate(180deg);
}

.NFTItem_vStack__3PMvQ {
    align-items: flex-start !important;
}

.NFTItem_link__2OJVf {
    color: white !important;
    /*font: 1.3rem condensedRegular3270, monospace;*/
    /*text-shadow: 0 0 5px #C8C8C8;*/
}

.NFTItem_link__2OJVf:hover {
    color: #63cd18 !important;
    text-decoration: none !important;
}

.NFTItem_link__2OJVf:focus {
    text-decoration: none !important;
    border: none !important;
    box-shadow: none !important;
}

.NFTItem_link__2OJVf:active {
    text-decoration: none !important;
}

.NFTsList_vStack__3sppf {
    margin: 200px 12px 12px;
    overflow: auto;
}

.Legend_container__3PsFG {
    padding: 3px;
    margin: 3px;
    background-color: #171923;
    box-shadow: none !important;
}

.Legend_container__3PsFG p {
    margin-left: 12px;
    font-size: 0.8em !important;
    box-shadow: none !important;
}




.Channel_sendInput__3jQQw:focus {
    border-color: #1AFF80 !important;
    /*outline: none !important;*/
}

.Channel_sendButton__1k_w0 {

}
.Frame_frame__14mnz {
    width: 1102px;
    height: 702px;
    /* TODO - change with position*/
    margin: 200px 0 30px 60px;
    background-image: url(/static/media/frame.561eed10.svg);
    background-position: center;
    background-repeat: no-repeat;
}

.Frame_frameLine__3z8GX {

    width: 1133px;
    height: 15px;
    margin-left: 31px !important;
    margin-bottom: 30px !important;
    background-image: url(/static/media/frameLine.94241a2d.svg);
    background-position: center;
    background-repeat: no-repeat;
}

.Frame_network__2fsb2 {
    margin-left: 40px !important;
    margin-right: 15px !important;
    padding-bottom: 4px;
    font: 0.9rem condensedRegular3270, monospace;
    text-shadow: none;
}

.UserNotLoggedIn_vStack__YxL29 {
    padding: 12px;
    margin: 120px 12px 12px;
    align-items: flex-start !important;
    flex-direction: column;
}

.UserNotLoggedIn_text__2pH8x {
    text-transform: uppercase;
}


.UserNotLoggedIn_input__2TRj4 {
    /*position: relative;*/
    caret-color: transparent !important;
}

[contenteditable] {
    caret-color: transparent; /** This hides the default browser caret */
    outline: none;
}

/* Puts a blinking square after the content as replacement for caret */
.UserNotLoggedIn_input__2TRj4:after,
.UserNotLoggedIn_typer__2VG7H.UserNotLoggedIn_active__U0Bnd:after {
    content: "■";
    animation: UserNotLoggedIn_cursor__15zKx 1s infinite;
    animation-timing-function: step-end !important;
    /*margin-left: 1px !important;*/
    /*line-height: 1rem !important;*/
}

.UserNotLoggedIn_typer__2VG7H {
    word-break: break-all;
}

/* Inserts the > before terminal input */
.UserNotLoggedIn_input__2TRj4:before,
.UserNotLoggedIn_typer__2VG7H:before {
    content: ">" !important;
    /*position: absolute;*/
    /*padding-left: 1.5rem;*/
    /*left: 0;*/
}

@keyframes UserNotLoggedIn_cursor__15zKx {
    0% {
        opacity: 1;
    }
    25% {
        opacity: 0;
    }
    75% {
        opacity: 1;
    }
    100% {
        opacity: 1;
    }
}

.Authentication_address__2vUA7 {
    max-width: 220px;
}

.Authentication_button__1BBSP {
    color: white;
}
.Authentication_button__1BBSP:focus {
}

.Authentication_button__1BBSP:hover {
    color: #63cd18;
    text-decoration: none !important;
}

.Authentication_warning__ZhLv9 {
    color: #63cd18 !important;
    text-align: right !important;
}


.Header_container__X8QFV {
    width: 100%;
    padding: 15px;
    position: fixed;
    /*z-index: 10;*/
}

.Header_link__cU-VI {
    color: white !important;
    /*font: 1.3rem condensedRegular3270, monospace;*/
    /*text-shadow: 0 0 5px #C8C8C8;*/
}

.Header_link__cU-VI:hover {
    color: #63cd18 !important;
    text-decoration: none !important;
}

.Header_link__cU-VI:focus {
    text-decoration: none !important;
    border: none !important;
    box-shadow: none !important;
}

.Header_link__cU-VI:active {
    text-decoration: none !important;
}


.Background_body__1FHr6 {
    background-color: black;
    background-image: radial-gradient(
            /*rgba(14, 96, 14, 0.75), black 120%*/
            rgba(0, 150, 0, 0.75), black 120%
    );
    height: 100vh;
    margin: 0;
    overflow: auto;

    /*color: #63cd18;*/
    color: white;
    font: 1.3rem condensedRegular3270, monospace;
    text-shadow: 0 0 5px #C8C8C8;
}

.Background_body__1FHr6::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;

    /* allows selecting the text */
    pointer-events: none;

    background: repeating-linear-gradient(
            0deg,
            rgba(0,0,0, 0.15),
            rgba(0,0,0, 0.15) 1px,
                /*transparent 1px,*/
                /*transparent 2px*/
            transparent 3px,
            transparent 5px
    );
}

 /* selected text is blue */
::selection {
    background: #0080FF;
    text-shadow: none;
}

.Background_scanline__34ecY {
    width: 100%;
    height: 100px;
    z-index: 8;
    background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 0) 0%,
            rgba(255, 255, 255, 0.2) 10%,
            rgba(0, 0, 0, 0.1) 100%
    );
    opacity: 0.1;
    position: absolute;
    bottom: 100%;
    animation: Background_scanline__34ecY 10s linear infinite;
}

@keyframes Background_scanline__34ecY {
    0% {
        bottom: 100%;
    }
    80% {
        bottom: 100%;
    }
    100% {
        bottom: 0%;
    }
}
.ItemDetails_image__zusqf {
    width: 200px;
}

.ItemDetails_name__1Nn_r {
    /*border: 1px solid red;*/
    width: 100px !important;
    margin: 0 !important;
    padding: 0 !important;
}

.ItemDetails_details__1AQXy {
    /*border: 1px solid red;*/
}

.ItemDetails_link__1zqli {
    color: white !important;
    /*font: 1.3rem condensedRegular3270, monospace;*/
    /*text-shadow: 0 0 5px #C8C8C8;*/
}

.ItemDetails_link__1zqli:hover {
    color: #63cd18 !important;
    text-decoration: none !important;
}

.ItemDetails_link__1zqli:focus {
    text-decoration: none !important;
    border: none !important;
    box-shadow: none !important;
}

.ItemDetails_link__1zqli:active {
    text-decoration: none !important;
}


