.container {
    padding: 3px;
    margin: 3px;
    background-color: #171923;
    box-shadow: none !important;
}

.container p {
    margin-left: 12px;
    font-size: 0.8em !important;
    box-shadow: none !important;
}
