.vStack {
    align-items: flex-start !important;
}

.link {
    color: white !important;
    /*font: 1.3rem condensedRegular3270, monospace;*/
    /*text-shadow: 0 0 5px #C8C8C8;*/
}

.link:hover {
    color: #63cd18 !important;
    text-decoration: none !important;
}

.link:focus {
    text-decoration: none !important;
    border: none !important;
    box-shadow: none !important;
}

.link:active {
    text-decoration: none !important;
}
